@use '../../global' as *;

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 2px solid $darkgrey-color-2;
    background-color: $secondary-color;
    z-index: 100;
    
    nav {
        height: 100%;
        @media screen and (min-width: $tablet) {
            justify-content: center;
        }

        .logo_ctn {
            position: absolute;
            height: 100%;

            @media screen and (min-width: $tablet) {
                display: flex;
                margin-left: 1rem;
            }

            img {
                width: 60px;
                height: 48px;
                margin: 5px 0;
            }
            
        }

        .links_ctn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            
            a {
                margin: auto 0;
                padding: .4rem .8rem;
                font-family: $title-font;
                font-size: 1.4rem;
                color: $primary-color;
                text-transform: uppercase;
                transition: color .3s;
                
                @media screen and (min-width: $tablet) {
                    display: block;
                    padding: 2rem;
                    border-top: 1px solid $darkgrey-color-2;
                    font-size: 1.6rem;
                }

                &:hover {
                    color: $tertiary-color-1;
                }
            }
        }
    }
}
@use '../../global' as *;

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    aspect-ratio: 1;
    
    @media screen and (min-width: $tablet) {
        width: 80px;
    }

    >path {
        &:first-child {
            stroke: $tertiary-color-2;
        }

        &:last-child {
            stroke: $tertiary-color-1;
            fill: $tertiary-color-3;
            -webkit-animation: spin 1.5s linear infinite;
            -moz-animation: spin 1.5s linear infinite;
            -o-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
        }
    }
}

@keyframes spin {
    0%   { 
        transform-origin: 50% 50%; 
        transform: rotate(0deg); 
    }
    100% { 
        transform-origin: 50% 50%; 
        transform: rotate(360deg); 
    }
}
@use '../../global' as *;

// !!! IMPORTANT !!!
//
// Position 'fixed' doesn't work if some of the parents has 'transorm' applied!

.modal_ctn {
   position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.9);
    z-index: 301;
    
    .project_modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 80%;
        max-width: 460px;
        max-height: calc(100% - 2 * calc($header-height * 4 / 3));
        padding: 1.5rem 1rem 1rem;
        border-radius: 8px;
        text-align: left;
        background-color: $darkbluegrey-color-2;

        @media screen and (min-width: $tablet){
            max-width: 500px;
            padding: 2rem;
        }

        .close_btn {
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            border: none;
            font-size: 30px;
            color: white;
            background-color: transparent;
        }

        &_img_ctn {
            width: 100%;
            outline: 1px solid $lightgrey-color-1;
            border: 2px solid $darkgrey-color-2;
            border-radius: 4px;
            background-color: black;
            transition: opacity .4s;

            @media screen and (min-width: $tablet) {   
                margin: 0 auto;
            }

            > img {
                height: 100%;
                aspect-ratio: 2.05;
                margin: 0 auto;
                border-radius: 4px;
                object-fit: contain;
            }
        }

        &_info {
            position: relative;
            max-width: 800px;
            margin: 2rem 0;
            padding: 0 1rem;
            color: $primary-color;
            overflow-y: scroll;
            @media screen and (min-width: $tablet) {   
                width: 100%;
                margin: 2rem 0 1rem;
                //padding: 0 1;
            }

            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background: $darkgrey-color-3;
            }
            &::-webkit-scrollbar-thumb {
                background: $grey-color;
                &:hover {
                    background: $lightgrey-color-1;
                }
            }

            .info_header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h2 {
                    margin: 0;
                    font-size: 2rem;
                }

                .links {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    
                    > a {
                        position: relative;
                        height: 100%;
                        padding: 0 .5rem;
                        font-size: 1.6rem;
                        @media screen and (min-width: $tablet) {    
                            top: .2rem;
                        }

                        &:hover {
                            > span {
                                opacity: 1;
                            }
                        }
        
                        > span {
                            display: none;
                            font-size: 1.4rem;
                            @media screen and (min-width: $tablet) {    
                                display: inline-block;
                                margin-right: .5rem;
                            }
                        }

                        & + a {
                            margin-left: 2rem;
                            @media screen and (min-width: $tablet) {    
                                margin-left: 1rem;
                            }
                        }
                    }
                }
            }

            .subtitle {
                margin: .5rem 0 2rem;
                font-family: $title-font;
                color: $tertiary-color-2;
            }

            .tools {
                margin: 1rem 0 2rem;
            }
        }
    }
}

.pulsate {
    animation: pulsate 2.5s ease-in-out;
    animation-iteration-count: infinite; 
    opacity: .5;
}

@keyframes pulsate {
    0% { 
        opacity: .5;
    }
    50% { 
        opacity: 1;
    }
    100% { 
        opacity: .5;
    }
}
@use '../../global' as *;

.contact_section {
    display: flex;
    flex-wrap: wrap;
    padding: $header-height 2rem 4rem;
    
    @media screen and (min-width: $tablet) {
        padding: $header-height 4rem 4rem;
    }

    h1 {
        width: 100%;
    }

    .contact_text {
        width: 100%;
        margin: 0 0 3rem;
        text-align: justify;
        @media screen and (min-width: $tablet) {
            margin: 0 0 2rem;
            text-align: center;
        }
    }

    &_details {
        display: inline-block;
        width: 100%;
        margin: auto;
        padding: 1rem;
        border-radius: .6rem;
        background-color: $darkbluegrey-color-2;
        @media screen and (min-width: $tablet) {
            display: inline-block;
            width: calc(50% - 2rem);
            margin: 6.2rem 2rem auto 0;
        }
        @media screen and (min-width: $desktop) {
            width: unset;
            margin: 6.2rem auto auto;
        }

        .detail_ctn {
            //width: 100%;
            margin: 0 4rem .5rem;
            text-align: left;
            @media screen and (min-width: $tablet) {
                margin-bottom: 1rem;
            }

            > * {
                display: inline-block;
                vertical-align: top;

                &:first-child {
                    color: $lightgrey-color-1;
                }

                > svg {
                    margin-right: 1rem;
                }
            }
        }
    }

    &_form_ctn {
        width: 100%;
        max-width: 400px;
        margin: 2rem auto 0;

        @media screen and (min-width: $tablet) {
            display: inline-block;
            width: 50%;
            margin-top: 0;
        }

        > h2 {
            font-size: 2rem;
        }

        .ok_msg,
        .err_msg {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            padding: 0 1.5rem 2rem;
            font-size: 1.4rem;
            color: $highlight-color;
            white-space: break-spaces;
        }
        .ok_msg {
            font-weight: 500;
            color: rgb(48, 248, 118);
        }

        .contact_form {
            display: flex;
            flex-direction: column;
            align-items: center;

            > * {
                margin-bottom: 1rem;
            }

            > input,
            > textarea {
                display: block;
                width: 100%;
                padding: .6rem;
                box-shadow: inset 1px 1px 2px $secondary-color;
                font-weight: 600;
                overflow: visible;

                &:focus {
                    outline: 2px solid $tertiary-color-1;
                }
            }
            > input {
                color: $secondary-color;
            }
            > textarea {
                height: auto;
                margin-bottom: .5rem;
            }

            .counter {
                width: 100%;
                margin: 0 auto 1rem .5rem;
                font-size: 1rem;
                text-align: left;
            }

            > button {
                margin-top: 1rem;
                padding: .6rem 1rem;
                font-weight: 800;
                font-size: 1.6rem;
            }
        }
    }
}
// ---------------------- Colors ---------------------- //
$primary-color: white;                 
$secondary-color: #121b2b;
$tertiary-color-1: #ffdd00;              
$tertiary-color-2: #d7bd16;  
$tertiary-color-3: #b59f14;           

$highlight-color: #e71369;   // highlight texts, errors

$darkbluegrey-color-1: #232934; 
$darkbluegrey-color-2: #333d4e; 
$bluegrey-color: #525d70;  

$darkgrey-color-1: #303030;  
$darkgrey-color-2: #444;       // input, textarea, select...
$darkgrey-color-3: #5f5f5f;  
$grey-color: #808080;
$lightgrey-color-1: #cccccc;   // table cells borders
$lightgrey-color-2: #e7e7e7;
$lightgrey-color-3: #f9f9f9;    // alternate table rows

// ----------------------- Fonts ---------------------- //
$main-font: 'Manrope', sans-serif;      
$title-font: 'Anta', sans-serif;    // for headings

// ------------ Breakpoints (media queries) ----------- //
$tablet: 768px;
$desktop: 1100px;

// ----------------------- Other ---------------------- //
$header-height: 60px;
$footer-height: 35px;
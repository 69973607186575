@use '../../global' as *;

@mixin button() {
    width: 25px;
    height: 25px;
    margin: 5px;
    border: none;
    border-radius: 50%;
    outline: 2px solid $lightgrey-color-1;
    background-color: transparent;
    transition: all .3s;

    &:hover {
        outline-color: $tertiary-color-1;
    }

    & + & {
        margin-bottom: 10px;
    }

    > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: fit;
    }

    > span {
        display: none;
        position: absolute;
        top: 20px;
        right: -20px;
    }
}

.switch_lang_ctn {
    position: absolute;
    top: 7px;
    right: 5px;
    display: flex;
    justify-content: center;
    width: 45px;
    height: 115px;
    padding: 5px;

    @media screen and (min-width: $tablet) {
        left: unset;
        right: 20px;
    }

    .current_lang_btn {
        @include button();
        z-index: 10;
    }

    .lang_menu {
        position: absolute;
        width: 35px;
        height: 105px;
        border-radius: 20px;
        background-color: $grey-color;
        transition: opacity .5s ease-out;
        z-index: 20;

        .lang_btn {
            position: relative;
            @include button();
        }
        .grey_outline {
            outline-color: $darkbluegrey-color-2;
        }
    }
}

.show_lang_menu {
    .lang_menu {
        opacity: 1;
        z-index: 20;

        .lang_btn {
            display: inline-block;
        }
    }
}

.hide_lang_menu {
    .lang_menu {
        opacity: 0;
        z-index: 0;

        .lang_btn {
            display: none;
        }
    }
}
@use '../../global' as *;

@mixin property($variable: variable, $syntax: "<color>", $initial-value: #000000, $inherits: false){
    @property --#{$variable} {
        syntax: $syntax;
        initial-value: $initial-value;
        inherits: $inherits;
    }
}

@include property($variable: gradient-border, $syntax: "<color>", $initial-value: $darkbluegrey-color-2, $inherits: false);
@include property($variable: gradient-center, $syntax: "<color>", $initial-value: $bluegrey-color, $inherits: false);

.project_card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 1.5rem 1rem 1rem;
    border-radius: 8px;
    //background: linear-gradient(to bottom, $darkbluegrey-color-2, $bluegrey-color 50%, $darkbluegrey-color-2 100%);
    background: linear-gradient(to bottom, var(--gradient-border), var(--gradient-center) 50%, var(--gradient-border) 100%);
    transition: --gradient-border .8s ease-in, --gradient-center .8s ease-in;
    &:hover {
        cursor: pointer;
        //background: linear-gradient(to bottom, $bluegrey-color, $lightgrey-color-1 50%, $bluegrey-color 100%);
        --gradient-border: #525d70;   
        --gradient-center: #cccccc;
    }

    @media screen and (min-width: $tablet){
        padding: 2rem;
    }

    & + .project_card {
        margin-top: 2rem;
        @media screen and (min-width: $tablet){
            margin-top: 0;
        }
    }

    &_img_ctn {
        width: 100%;
        outline: 1px solid $lightgrey-color-1;
        border: 2px solid $darkgrey-color-2;
        border-radius: 4px;
        background-color: black;

        @media screen and (min-width: $tablet) {   
            margin: 0 auto;
        }

        .blur_ctn {
            height: 100%;
            aspect-ratio: 2;
            margin: 0 auto;
            border-radius: 4px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            img {
                height: 100%;
                width: 100%;
                border-radius: 4px;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    &_info {
        position: relative;
        max-width: 800px;
        padding: 1rem;

        @media screen and (min-width: $tablet) {   
            width: 100%;
            margin-top: 1.5rem;
            padding: 0 1rem;
        }

        h2 {
            margin: 0;
            font-size: 2rem;
        }
    }
}
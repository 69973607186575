@use '../../global' as *;

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: $footer-height;
    overflow: hidden;
    margin-top: auto;
    font-size: 1.4rem;
    text-align: center;
    background-color: $secondary-color;

    .footer_legal {
        width: 100%;

        p {
            display: block;
            font-size: 1.2rem;
            color: $grey-color;// black;
        }
    }
}
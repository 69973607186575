@use '../../global' as *;

.intro {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: white;
    overflow: hidden;

    &_section {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .motion_logo_ctn {
            width: 80%;
            max-width: 360px;
            height: 80%;
        }
    }
}
@use '../../global' as *;

.about_section {
    padding: $header-height 3rem 0;

    .about_article {
        padding: 1rem 0;
        color: $primary-color;

        .profile_img {
            width: 40%;
            aspect-ratio: 1/1.2;
            max-width: 180px;
            float: left;
            margin-right: 1.5rem;

            @media screen and (min-width: $tablet){
                margin-right: 2rem;
            }
        }

        >section + section {
            margin-top: 3rem;
        }

        .description {
            text-align: left;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            @media screen and (min-width: $tablet){
                text-align: justify;
            }

            h2 {
                font-size: 2rem;
                text-transform: uppercase;
                @media screen and (min-width: $tablet){
                    font-size: 2.2rem;
                }
            }

            > a {
                color: $tertiary-color-2;
            }

            .margin_10_0 {
                margin: 1rem 0; 
            }
        }
    }
}
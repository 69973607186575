@use './global' as *;

/*************************************************************/
/* ----------------------- GENERALES ----------------------- */
/*************************************************************/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body{
    font-family: $main-font;  
    font-weight: 300;
    font-size: 1.4rem;
    font-optical-sizing: none;
    background-color: $secondary-color;
    
    > div { // "root"
        min-height: 100vh; // pour positionner le footer toujours en bas de la page
        display: flex;
        flex-direction: column;

        main {
            text-align: center;
            
            @media screen and (min-width: $tablet) {
                margin-top: 0;
                background: radial-gradient($darkbluegrey-color-1, $secondary-color);
            }

            > section {
                max-width: 1000px;
                margin: 0 auto;
                padding: 0 2rem;
                color: $primary-color;
                @media screen and (min-width: $tablet){
                    padding: 0 8rem;
                }
            }
        }
    }
}

/* ------------ HEADINGS ------------- */
h1, h2, h3 {
    display: block;
    font-family: $title-font;
    font-weight: 500;
    color: $tertiary-color-1;
}

h1 {
	font-size: 3rem;
    text-transform: uppercase;
    margin: 2.2rem 0;
}

h2 {
	font-size: 2.5rem;
    margin: 1.8rem 0;
}

h3 {
	font-size: 2.2rem;
    margin: 1.6rem 0;
}

/* ------------- OTHERS -------------- */
a {
    display: inline-block;
    font-weight: 500;
    text-decoration: none;
    color: $tertiary-color-2;
}

li {
    list-style: none;
}

p {
    margin: 1rem 0;
}

img {
    display: block;
    width: 100%;
    max-height: 100%;
}

button {
    &:hover {
        cursor: pointer;
    }
}

/* ------------- TABLES -------------- */
table {
	border-collapse: collapse;
    font-size: 1.4rem;
    thead {
        th {
            padding: 1.4rem;
            border: 1px solid $lightgrey-color-1;
            font-weight: 600;
            text-align: center;
        }
    }
    tbody {
        tr {
            td {
                border: 1px solid $lightgrey-color-1;
                text-align: center;
            }
            &:nth-child(odd) {
                td {
                    background-color: $lightgrey-color-3;
                }
            }
        }
    }
    tfoot {
        th {
            border-top: 1px solid $grey-color;
        }
    }
}

/* ---------- FORM ELEMENTS ----------- */
input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="number"],
input[type*="date"],
select,
textarea {
	display: inline-block;
	font-size: 1.6rem;
    font-family: $main-font; 
	color: $darkgrey-color-2; 
	border: 1px solid $lightgrey-color-1;
	border-radius: .6rem;
}
textarea {
	display: block;
    resize: none;
}

::placeholder {
    font-weight: 400;
	color: $grey-color; 
}

/* ----------- CUSTOM CLASSES ------------ */

.hidden {
    display: none;
}

.margin_10_0 {
    margin: 1rem 0;
}
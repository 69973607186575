@use '../../global' as *;

#notFound {
    padding: calc($header-height + 4rem) 2rem 0;
    
    p {
        color: $tertiary-color-2;
    
        a {
            margin-left: .5rem;
            padding: .3rem .5rem;
            border: 1px solid $lightgrey-color-1;
            border-radius: 4px;
            font-weight: 500;
            color: $tertiary-color-1;
            background-color: $darkbluegrey-color-2;
            transition: all .5s;
    
            &:hover {
                color: $secondary-color;
                background-color: $lightgrey-color-1;
            }
        }
    }
}